import React from 'react';
import Module from "../Module";

export default class AnswerCategoriesModule extends Module {
  active = false;
  categories = [];
  showSummary = false;
  showFeedback = false;
  feedback = [];

  answersByCategory = {};
  totalAnswers = 0;

  constructor(parameters, questions, exerciseParameters) {
    super(parameters, questions, exerciseParameters);
    this.active = parameters['active'];
    this.categories = parameters['categories'];

    if (parameters['showSummary'] !== undefined) {
      this.showSummary = parameters['showSummary']
    }

    if (parameters['showFeedback'] !== undefined) {
      this.showFeedback = parameters['showFeedback'];
      this.feedback = parameters['feedback'];
    }
  }

  isActive() {
    return this.active;
  }

  answerChosen(answer) {
    const category = answer['parameters']['modules.answer_categories.category'];

    if (this.answersByCategory[category] === undefined) {
      this.answersByCategory[category] = 0;
    }

    this.answersByCategory[category]++;
    this.totalAnswers++;
  }

  shouldShowSummary() {
    return this.showSummary || this.showFeedback;
  }

  renderSummary() {
    if (!this.showSummary && !this.showFeedback) {
      return super.renderSummary();
    } else {
      return <>
        {this.showSummary &&
          <this.Summary />
        }
        {this.showFeedback &&
          <this.Feedback />
        }
      </>
    }
  }

  Summary = () => {
    return <>
      <h2>Twój wynik to:</h2>
      <table className="ranking">
        <tbody>
        {this.getSortedCategoryEntries()
          .map(([categoryId, answersCount]) => (
            <tr key={categoryId}>
              <td>{this.categories[categoryId]}</td>
              <td>{Math.round((answersCount/this.totalAnswers) * 100)}%</td>
            </tr>
          ))
        }
        </tbody>
      </table>
      </>
  };

  Feedback = () => {
    let feedback = [];
    const sortedCategories = this.getSortedCategoryEntries();
    const maxAnswersCount = sortedCategories[0][1];

    for (const [categoryId, answersCount] of sortedCategories) {
      if (answersCount === maxAnswersCount) {
        feedback.push(this.feedback[categoryId]);
      } else {
        break
      }
    }

    return <>
      {feedback.map((content, index) => (
        <h3 key={index}>{content}</h3>
        )
      )}
    </>
  };

  getSortedCategoryEntries = () => {
    return Object.entries(this.answersByCategory)
      .sort(([_A, valueA], [_B, valueB]) => (valueB - valueA));
  }
}