import React from 'react';
import _, {defaultTo} from 'lodash';

import ExerciseComponent, {DEFAULT_STATES} from "base/ExerciseComponent";

import AnswerCard from "components/AnswerCard/AnswerCard";
import Animations from "lib/Animations";

import InstructionCard from "components/InstructionCard/InstructionCard";
import AnimatedElement from "components/AnimatedElement/AnimatedElement";
import ChosenAnswerStatsModule from "exercises/modules/stats/ChosenAnswerStatsModule";

import './TilesExercise.scss'
import Button from "../../../components/Button/Button";

const ANIMATION_SPEED = 1000;

export const DEFAULT_TILES_CORRECT_POINTS = 2;

const STATES = {
  ...DEFAULT_STATES,
  QUESTION_APPEARING: 1,
  QUESTION_ANSWERING: 2,
  QUESTION_ANSWERED: 3,
  QUESTION_HIDING: 4,
};

export default class TilesExercise extends ExerciseComponent {
  static exerciseClass = "TilesExercise";
  static defaultParameters = {
    timePerQuestionSeconds: 15,
    answersHidden: false,
  };

  static usedModules = [ChosenAnswerStatsModule];

  chosenAnswerIds = [];
  timeout = null;

  pointsPerQuestion;

  static maxPoints(questions, parameters) {
    return questions.length * defaultTo(parameters['pointsPerQuestion'], DEFAULT_TILES_CORRECT_POINTS);
  }

  constructor(props) {
    super(props);

    Animations.newBurst('correct', 'rgb(255, 120, 0)', 'rgb(0, 198, 24)');
    Animations.newBurst('incorrect', 'rgb(255, 120, 0)', 'rgb(255, 13, 0)');

    const {questions, parameters} = props;

    this.state = {
      ...this.state,
      playing: true,
      visible: true,
      gameFinished: false,

      unblurredAnswer: undefined,

      showAnswers: true,
      showQuestion: true,
      showFeedback: false,

      questions: _.clone(props.questions),
      currentQuestionIndex: 0,
    };

    this.timePerQuestionSeconds = parameters.timePerQuestionSeconds;
    this.maxPoints = TilesExercise.maxPoints(questions, parameters);
    this.pointsPerQuestion = defaultTo(parameters['pointsPerQuestion'], DEFAULT_TILES_CORRECT_POINTS);
    this.showNeutralFeedback = parameters["showNeutralFeedback"];

    this.prepareQuestions();
    this.onState(STATES.QUESTION_ANSWERING, this.questionAppeared);
  }

  usedModules(questions, parameters) {
    return [
      new ChosenAnswerStatsModule({}, questions, parameters),
    ]
  }

  prepareQuestions = () => {
    const {answersHidden} = this.parameters;

    for (let question of this.state.questions) {
      question.answers = _.shuffle(question.answers);

      for (let answerIndex in question.answers) {
        if (question.answers.hasOwnProperty(answerIndex)) {
          let answer = question.answers[answerIndex];
          _.extend(answer, {
            neutralFeedback: this.showNeutralFeedback,
            correct: answer.correct,
            active: true,
            visible: true,
            blurred: answersHidden,
            showFeedback: false,
            index: answerIndex,
          })
        }
      }
    }
  };

  renderExercise = (state, props) => {
    const {questions} = props;

    return <>
      <AnimatedElement
        visible={!this.inStates([STATES.QUESTION_HIDING])}
        animation={AnimatedElement.AnimationTypes.slideLeft}
        durationMs={ANIMATION_SPEED}
      >
        <InstructionCard
          countType="Pytanie"
          countCurrent={this._currentQuestionCount()}
          countMax={questions.length}
          mainText={this._currentQuestionContent()}
        />
      </AnimatedElement>
      <AnimatedElement visible={!this.inStates([STATES.QUESTION_APPEARING, STATES.QUESTION_HIDING])}>
        <div className="answers-container">
          {this.renderAnswers()}
        </div>
      </AnimatedElement>
      <AnimatedElement className="container-next" visible={this.inStates(STATES.QUESTION_ANSWERED)} animation={AnimatedElement.AnimationTypes.popOut} appearDelayMs={2000}>
        <Button onClick={this.continueGame} big>
          {this.isLastQuestionShown() ? "Zakończ ćwiczenie" : "Następne pytanie"}
        </Button>
      </AnimatedElement>
    </>;
  };

  startGame = () => {
    this.setCurrentStateSequence([STATES.QUESTION_APPEARING, STATES.QUESTION_ANSWERING], ANIMATION_SPEED);
  };

  renderAnswers = () => {
    return this._getCurrentAnswers().map((answer, index) => {
      return (
        <AnswerCard isCorrect={answer.correct} isBlurred={answer.blurred} answer={answer}
          disabled={!this.inState(STATES.QUESTION_ANSWERING)} onClick={this.answerChosen} onBlurredClick={this.blurredChosen}
          key={index}
        >
          {answer.content}
        </AnswerCard>
      );
    });
  };

  blurredChosen = (answer) => {
    if (!this.state.playing) {
      return;
    }

    this.setState((state) => {
      let {questions, currentQuestionIndex, unblurredAnswer} = state;

      if (unblurredAnswer) {
        questions[currentQuestionIndex].answers[unblurredAnswer.index].blurred = true;
      }
      unblurredAnswer = answer;
      questions[currentQuestionIndex].answers[answer.index].blurred = false;

      return {
        questions,
        unblurredAnswer,
      };
    });
  };

  answerChosen = (answer, event) => {
    super._answerChosen(answer);

    if (!this.state.playing) {
      return;
    }

    let position = [event.clientX, event.clientY];
    let pointsChange = 0;

    this.chosenAnswerIds.push(answer.id);

    if (this.pointsPerQuestion === 0 || answer.correct) {
      pointsChange = DEFAULT_TILES_CORRECT_POINTS;

      Animations.playBurst('correct', position);
    } else {
      Animations.playBurst('incorrect', position);
    }

    this.setCurrentState(STATES.QUESTION_ANSWERED, () => {
      this.setState((state) => {
        return {
          points: state.points + pointsChange,
        }
      }, this.unBlurCurrentAnswers);
    });
  };

  timeRanOut = () => {
    this.setCurrentState(STATES.QUESTION_ANSWERED, this.unBlurCurrentAnswers);
  };

  unBlurCurrentAnswers = () => {
    this.setState((state) => {
      let {questions, currentQuestionIndex} = state;
      for (let answer of questions[currentQuestionIndex].answers) {
        answer.blurred = false;
      }

      return {
        questions,
      }
    });
  };

  _getActiveQuestionForState = (state = this.state) => {
    return state.questions[state.currentQuestionIndex]
  };

  continueGame = () => {
    let nextStep, nextState;
    if (this.isLastQuestionShown()) {
      this.finishData = {
        chosenAnswerIds: this.chosenAnswerIds,
      };

      nextState = STATES.FINISHING;
    } else {
      nextState = STATES.QUESTION_HIDING;
      nextStep = this.showNextQuestion
    }

    this.setCurrentState(nextState, () => {
      this.timeout = setTimeout(nextStep, ANIMATION_SPEED);
    });
  };

  isLastQuestionShown = () => {
    return this.state.currentQuestionIndex >= this.state.questions.length - 1;
  };

  showNextQuestion = () => {
    this.setState((state) => {
      return {
        currentQuestionIndex: state.currentQuestionIndex + 1,
      }
    }, () => {
      this.setCurrentStateSequence([STATES.QUESTION_APPEARING, STATES.QUESTION_ANSWERING], ANIMATION_SPEED);
    });
  };

  questionAppeared = () => {
    const question = this._getActiveQuestionForState();
    super._questionAppeared(question);
  };

  isClockRunning = () => {
    return this.inState(STATES.QUESTION_ANSWERING);
  };

  _currentQuestionContent = () => {
    return this._getActiveQuestionForState(this.state).content;
  };

  _currentQuestionCount = () => {
    return this.state.currentQuestionIndex + 1
  };

  _getCurrentAnswers = () => {
    return this._getActiveQuestionForState(this.state).answers
  };

  componentWillUnmount() {
    Animations.reset();
    clearTimeout(this.timeout);
    super.componentWillUnmount();
  }
}
